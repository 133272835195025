import React from 'react'

import {graphql} from 'gatsby'
import PropTypes from 'prop-types'

import Seo from '@/components/atoms/Seo'
import Blocks from '@/components/molecules/Blocks'
import LastNews from '@/components/organisms/LastNews'
import WordPressProvider from '@/components/providers/WordPressProvider'
import getMenus from '@/functions/wordpress/menus/getMenus'
import LayoutDefault from '@/layouts/LayoutDefault'

export default function Page({data}) {
  const menus = getMenus(data?.allWpMenu)
  const seo = data?.wp?.seo
  const themeSettings = data?.wp?.evaluarTheme?.settings

  return (
    <WordPressProvider value={{menus, seo, themeSettings}}>
      <LayoutDefault>
        <Seo
          seoConfig={seo}
          postTypeSeo={data?.wpPage?.seo}
          language={data?.wpPage?.language}
        />
        <Blocks blocks={data?.wpPage?.acfPageBuilder?.acfBlocks} />
        <LastNews className="pt-16 md:pt-24" />
      </LayoutDefault>
    </WordPressProvider>
  )
}

Page.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object
}

export const query = graphql`
  query GET_PAGE_BY_ID($id: String!) {
    wpPage(id: {eq: $id}) {
      ...WpPageFragment
    }
    wp {
      ...WpFragment
    }
    allWpMenu {
      ...WpMenuConnectionFragment
    }
  }
`
